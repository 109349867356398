.unser__weg{

    padding-bottom: 8vw;
    &__punkt {
        padding-top: 8vw;
        padding-bottom: 4vw;
        position: relative;

        &:after {
            position: relative;
            display: block;
            content:"";
            bottom: -4vw;
            left: 0;
            width: 100%;
            height: 4px;
            background: black;

        }

        & h3 {
            font-size: 4vw;
        }

        & p {
            font-size: 2vw;
        }
    }
    
}
.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000000000;
    background: white;
    transform-origin: center bottom;
    transition: 600ms all cubic-bezier(1, 0, 0, 1);


    &__loading{
        background: white;
        position: fixed;
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        z-index: 10000;
    }
}
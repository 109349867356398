.methods{
    &__list{
        &__item{
            list-style-type: none;
            font-family: 'silkabold';
            
        }
    }

    &__link {
        color: black;
        

        &:hover {
            color: red;
            text-decoration: none;
        }
    }
}

.marquee{
    
    position: relative;
    overflow: hidden;
    padding: 3vh 0;
    --offset: 120vw;
    --move-initial: calc(-50% + var(--offset));
    --move-final: calc(-50%+ var(--offset));
    cursor: pointer;

    &__inner{
        width: fit-content;
        display: flex;
        position: relative;
        transform: translate3d(var(--move-initial),0,0);
        animation: marquee 5s linear infinite;
        animation-play-state: running;
    }

    &:hover{
        color:red;
    }

    & span {
        font-size: 10vw;
        padding: 0 2vw;
    }
    &:after{
        position: absolute;
        content: "";
        top: 0;
        height: 2px;
        background: black;
        width:100%;
        z-index: 11;
    }
    &:nth-last-child(1){
        &::before{
            position: absolute;
        content: "";
        bottom: 0;
        height: 2px;
        background: black;
        width:100%;
        z-index: 11;
        }
    }


    &__arrow__skew{
        position: absolute;
        height: 100%;
        top:0;
        right:0;
        background: white;
        z-index: 10;

        & svg {
            position: absolute;
            width:100%;
        }
    }
}


@keyframes marquee{
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
}
@import './utils/Silka-Roman-Webfont/stylesheet.css';
@import "~bootstrap/scss/bootstrap";
@import "./components/style/links.scss";
@import './components/style/headline.scss';
@import './components/style/hero.scss';
@import './components//style/animations.scss';
@import './components/style/methods.scss';
@import './components/style/partner.scss';
@import './components//style/overlay.scss';
@import './components/style/methodenSeite.scss';
@import './components/style/impressum.scss';
@import './components//style/unserweg.scss';
@import './components/style/client.scss';
@import './components/style/form.scss';
@import './components/style/footer.scss';
@import './components/style/allianz.scss';





html{
    font-family: 'silkaregular';
    scroll-behavior: smooth;
}

body{
    font-family: 'silkamedium';
    overflow-x: hidden;
    width: 100%;
    height: 100%;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    text-align: inherit;
  
    background: transparent;
  
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
  
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
  
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
  
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
  
    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  
}

button:focus{
    outline: none !important;
    border: none;
    box-shadow: none !important;
}

button:active{
    outline: none;
    border: none;
}
.block__element{
    display: block;
    overflow: hidden;
    position: relative;

    height: 7vw;
    line-height: .8;

    & span {
        position: absolute;
    }

}


h2 {
   margin: 0;
   font-family: 'silkaregular';


   font-size: 7vw;
       
   
}


.leading {
    font-family: 'silkaregular';
    margin-bottom: 4vw;
    font-size: 2.6vw;

    @media screen and (max-width: 414px) {
        font-size: 1.3rem
    }

    
}

.shuffle_p{

    font-size: 5vw;
    @media screen  and (max-width: 414px){
        font-size: 2rem;
    }
    @media screen  and (min-width: 768px){
        font-size: 4rem;
    }
    @media screen  and (min-width: 1024px){
        font-size: 4vw;
    }
    
    &:after {
        content:"→";
        display: block;
        position: absolute;
        color: black;
        top:50%;
        transform: translateY(-25%);
        right:50%;
        transition: all 600ms cubic-bezier(1, 0, 0, 1);

        @media screen and (max-width: 414px) {
            right: 10%;
        }
    }

    & a {
        color: black;
        &:hover{
            color: red;
            text-decoration: none;
        }
    }

    &:hover{
        &::after{
            color: red;
            right:0;
        }
    }
}


.h100{
    height: 100vh;
}


.mt-20{
    margin-top: 20vh;
}



.mt-menu{
    margin-top: 70px;
}

.page {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    overflow: hidden;
}

.contact{
    &__subhead {
        font-size: 5vw;
    @media screen  and (max-width: 414px){
        font-size: 2rem;
    }
    @media screen  and (min-width: 768px){
        font-size: 4rem;
    }
    @media screen  and (min-width: 1024px){
        font-size: 4vw;
    } 
    }
}
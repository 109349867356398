.shuffleAnimation{
    will-change: transform;
    animation-name: shuffleAnimation;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    display: block;
    overflow: hidden;

    &__wrapper{
        display: block;
        overflow: hidden;
        margin-left: 10px;
    }
}

@keyframes shuffleAnimation{
    0%{
        transform: translate3d(0, 106%,0);
    }
    25%{
        transform: translate3d(0, 0,0);
    }
    70%{
        transform: translate3d(0,0,0);
    }
    100%{
        transform: translate3d(0,-106%,0);
    }
}

@keyframes shuffleVerticalAnimation {
    0%{
        transform: translate3d(-100%, 0,0);
    }
    25%{
        transform: translate3d(0, 0,0);
    }
    70%{
        transform: translate3d(0,0,0);
    }
    100%{
        transform: translate3d(100%,0,0);
    }
}


.animated__word{
    position: absolute;
}
.marken{
    &_button{
        cursor: pointer;
        margin-bottom: 4vw;
        padding: 2vw 0;
        border-top: 1px solid black;
        border-bottom: 1px solid black;

        &:nth-last-child(1){
           
           
           @media screen and (min-width: 768px) {
            border-left: 1px solid black;

           } 

        }

        & a {
            &:hover {
                & svg {
                    fill: #FF0000;
                }
            }
        }
    }
}
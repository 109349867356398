.partner{
    padding: 6vh 0;

    &__card{
        margin-bottom: 6vh;
        &__img__wrapper{
            padding-bottom: 120.13%;
            position: relative;

            & img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__bg{
            position: absolute;
            height: 50%;
            width: 100%;
            background: linear-gradient(180deg,transparent 0,#000);
            z-index: 10;
            bottom:0;
        }
        &__head{
            color: white;
            font-family: 'silkaregular';
            position: absolute;
            bottom:2rem;
            left: 2rem;
            z-index: 12;

            & span {
                display: block;
            }
        }
        &__content{
            position: relative;
            padding-top: 20px;

            & ul {
                padding: 2rem;
            }

            & li {
                list-style-type: none;
                margin:0;
            }
            &:before{
                content: "";
                position: absolute;
                background: black;
                height: 4px;
                width: 100%;
                top:10px;
                
            }

            &:after {
                content:"";
                position: absolute;
                background: black;
                height: 4px;
                width: 100%;
                bottom: 10px;
            }
        }
    }
}
     /*   
        &__head{
            color: white;
            font-family: 'silkaregular';
            position: absolute;
            bottom:2rem;
            left: 2rem;

            & span {
                display: block;
            }
        }

        &__content{
            position: relative;
            padding-top: 20px;

            & ul {
                padding: 2rem;
            }

            & li {
                list-style-type: none;
                margin:0;
            }
            &:before{
                content: "";
                position: absolute;
                background: black;
                height: 4px;
                width: 100%;
                top:10px;
                
            }

            &:after {
                content:"";
                position: absolute;
                background: black;
                height: 4px;
                width: 100%;
                bottom: 10px;
            }
        }
    }*/


    .client {
        width: 10vw;
        height:100%;
        width: 100%;
        padding: 40px;

        & svg {
            height: 100%;
            width: 100%;
        }

    }

    .kunden{
        border-top: 3px solid black;
        padding: 10px;
        border-bottom: 3px solid black;
        margin-bottom: 5vw;
    }
.method__site{

    padding-top: 8.5vw;
    visibility: hidden;
    
    &__headline{
        font-size: 8.5vw;

        &__block {
            height: 8.5vw;
            line-height: .8;
            display: block;
            overflow: hidden;
            position: relative;

            & span {
                position: absolute;
                transform-origin: bottom left;
            }
        }
    }

    &__content{
        margin-top: 8vw;
        padding-bottom: 8vw;
        &__p{
            font-size: 2.5vw;

            @media screen and (max-width: 414px) {
                font-size: 1rem;
            }
        }
    }

    &__list{
        font-size: 2.5vw;
        padding: 1vw 0;
        position: relative;
        transition: all 600ms cubic-bezier(1, 0, 0, 1);

        @media screen and (max-width: 414px) {
            padding: 10px 0;
        }

        &:before{
            position: absolute;
            content: "";
            width: 100%;
            height:2px;
            top:0;
            background: black;
        }

        &:nth-last-child(1){
            &:after{
                position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            background: black;
            bottom:0;
            }
        }

      
    }
    
}
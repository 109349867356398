.footer{
    

    & p{
        @media screen and (max-width: 414px) {
           font-size:  .9rem;
        }
    }
    & a {
        color: black;
        cursor: pointer;
        &:hover{
            color: black;
        }
    }
}
.headline{
    position: fixed;
    height: 128px;
    @media screen and (max-width: 414px) {
        height: 72px;
    }

    z-index: 100;
    width:100%;
    top:0;

    &__container{
        height:100%;
    }

    &__logo{
        position: relative;
        height: 100%;


        & svg {
            position: absolute;
            top:50%;
            width:100%;
            transform: translateY(-50%);
        }
    }

    &__toggle{
        display: block;
        width: 90px;
        height: 40px;


    }

    &__burger{
        width: 100%;
    height: 100%;
    position: relative;
    display: block;

    cursor: pointer;

    &__line{
        width: 100%;
        height: 2px;
        background: #000;
        position: absolute;
        top: 50%;
        transform-origin: center;
        transition: all .6s cubic-bezier(1,0,0,1);
        &:first-child{
            transform: translate3d(0,6px,0) rotate(0);
        }

        &:last-child{
            transform: translate3d(0,-6px,0) rotate(0); 
        }
    }

    
    }
}


.headline__burger.true{
    .headline__burger__line{
        &:nth-child(1){
            transform: translate3d(0,0,0) rotate(-45deg);
        }
        &:nth-child(2){
            transform: translate3d(0,0,0) rotate(45deg); 
        }
    }
}


nav{
    height:100%;
    display: none;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:0%;
    z-index: 99;
    background: #FF2727;
    transition: all 600ms cubic-bezier(1, 0, 0, 1);
}


.menu__container {
    margin-top: 128px;
    
    padding-top: 15vh;
    @media screen and (max-width: 414px) {
        padding-top: 5vh;
    }
    & span {
        display:block;
        font-size: 1vw;
        margin-bottom: 3vh;
        @media screen and (max-width: 414px) {
            font-size: 1rem;
        }
    }

    & ul {

        font-size: 2vw;
        margin: 0;
        
        padding:0;
        

        @media screen and (max-width: 414px) {
            margin: 0 0 4vh 0;
            font-size: 2rem;
        }

        & li {
            margin-bottom: 2vw;
            list-style-type: none;
            color: black;
            position: relative;

            &:after {
                content:"";
                position: absolute;
                bottom: 0;
                left: 0;
                transform-origin: center left;
                height: 5px;
                width: 100%;
                background: black;
                transform: scaleX(0);
                transition: all 600ms cubic-bezier(1, 0, 0, 1);            
            }

            &:hover {
                &::after{
                    transform: scaleX(1);
                }
            }
             & a {
                 color: black;

                 &:hover {
                     text-decoration: none;
                 }
             }
        }
    }
}
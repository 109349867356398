.link__action{
    position: relative;
    padding: 6vh 0 1vh 0;
    cursor: pointer;
    
    &:hover{
        color:red;

        &::after{
            transform: scaleX(1);
            background: red;
        }

        

        & span {
            &:after{
                right:0;
                color: red;
            }

            & a {
                color: red;
            }
        }

    }
    
    &:after{
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        background: black;
        transform-origin: left;
        transform: scaleX(0);
        transition: all 600ms cubic-bezier(1, 0, 0, 1);
        bottom:0;
    }
}

.arrow__skew{
    position: relative;
    height: 5vw;
    width: 5vw;
    transform: translate3d(0, 0,0);
    transition: all 600ms cubic-bezier(1, 0, 0, 1);
    &__svg{
        position: absolute;
        height:100%;
        width: 100%;
        right:0;
    }
}

.link__action:hover .arrow__skew {
    transform: translate3d(30px, -30px,0);
}

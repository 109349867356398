.hero{
    height: calc(100vh - 70px);
    margin-top: 70px;

    &__castle{
        height: 33.3333%;
        position: relative;
        transform: translate3d(0,-50%, 0);

        @media screen and (min-width: 1024px){

            height:44.3333%;
        }

        & svg {
            width:100%;
            height: auto;
        }
    }

    &__title{

        height: 33.333%;

        @media screen and (min-width: 1024px){

            height:44.3333%;
        }

        &__position{
            height:100%;
        }
    }

    &__sub{

        transform: translateY(-50%);

        @media screen and (max-width: 320px) {
            transform: translateY(-20%);
        }

        @media screen and (max-width: 414px){
            transform: translateY(-20%);
        }
        
        &__p {
            padding:0;            
            font-size: 1.2rem;
            @media screen and (min-width: 768px) {
                font-size: 1.8rem;
            }
            
            @media screen and (min-width: 1024px) {
                
                
            }
            
        }

        
    }

    &__marken{
       

        & svg {
            width: 100%;
            opacity: .5;
            transition: all 900ms cubic-bezier(1, 0, 0, 1);
        }
    }
}


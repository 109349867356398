.impressum {
    padding-top: 8vw;
    margin-top: 70px;

    & a {
        color: black;
         &:hover {
             text-decoration: none;
             color: red;
         }
    }

    & h3 {
        font-family: 'silkabold';
    }

    & p {

        margin-bottom: 2vw;
        font-size: 2vw;
    @media screen  and (max-width: 414px){
        font-size: 2rem;
    }
    @media screen  and (min-width: 768px){
        font-size: 4rem;
    }
    @media screen  and (min-width: 1024px){
        font-size: 2vw;
    }
    }

    & li {
        list-style-type: none;
        font-size: 1.4vw; 
    }

    &__absatz{
        margin: 5vw 0;
    }

    &__aufzählung {
        margin: 3vw 0 !important;
        list-style-type: disc !important;
        list-style-position: outside !important;
        &:nth-last-child(1){
            margin-bottom: 4vw !important;
        }
    }
}